import { store } from "../redux/store";

class API {
  async loginAPI(username, password) {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/auth/jwt/create/",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      }
    );
    return response;
  }

  async refreshJWT(refreshToken) {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/auth/jwt/refresh/",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh: refreshToken,
        }),
      }
    );
    return response;
  }

  // Generic caller
  async getAPIData(url, method, body) {
    try {
      let response = await this.APICall(url, method, body);
      if (response.status === 401) {
        // Unauthorized

        // Get refresh token from store
        const state = store.getState();
        const refreshToken = state.user.refreshToken;

        // Refresh the token
        let refresh = await this.refreshJWT(refreshToken);

        // Refresh was successful, try the API Call again
        if (refresh.status === 200) {
          const newAccessToken = (await refresh.json()).access;
          store.dispatch({ type: "ACCESS", value: newAccessToken });
          return await this.APICall(url, method, body);
        } else {
          //   // If token can't be refresh, clear refresh and access token, and set login to false
          store.dispatch({ type: "ACCESS", value: null });
          store.dispatch({ type: "REFRESH", value: null });
          store.dispatch({ type: "LOGIN", value: false });
        }
      }
      return response;
    } catch (e) {
      return e;
    }
  }

  async UploadImage(url, body) {
    const state = store.getState();
    const accessToken = state.user.accessToken;

    let response = await fetch(url, {
      method: "POST",
      body: body,
      headers: {
        Authorization: "JWT " + accessToken,
      },
    });

    if (response.status === 401) {
      // Unauthorized

      // Get refresh token from store
      const state = store.getState();
      const refreshToken = state.user.refreshToken;

      // Refresh the token
      let refresh = await this.refreshJWT(refreshToken);

      // Refresh was successful, try the API Call again
      if (refresh.status === 200) {
        const newAccessToken = (await refresh.json()).access;
        store.dispatch({ type: "ACCESS", value: newAccessToken });
        return await fetch(url, {
          method: "POST",
          body: body,
          headers: {
            Authorization: "JWT " + newAccessToken,
          },
        });
      } else {
        store.dispatch({ type: "ACCESS", value: null });
        store.dispatch({ type: "REFRESH", value: null });
        store.dispatch({ type: "LOGIN", value: false });
      }
    }
    return await response;
  }

  async APICall(url, method, body) {
    const state = store.getState();
    const accessToken = state.user.accessToken;

    let requestParamaters = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
      // body: body && JSON.stringify(body)
    };

    // If it's not a GET, it's a PUT, POST or DELETE and we need to send a body
    if (method !== "GET") {
      requestParamaters["body"] = JSON.stringify(body);
    }

    return await fetch(url, requestParamaters);
  }

  async APICallUnauthorized(url, method, body) {
    let requestParamaters = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: body && JSON.stringify(body)
    };

    // If it's not a GET, it's a PUT, POST or DELETE and we need to send a body
    if (method !== "GET") {
      requestParamaters["body"] = JSON.stringify(body);
    }

    return await fetch(url, requestParamaters);
  }
}

const APIService = new API();
export default APIService;
